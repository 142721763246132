import React from 'react'
import { graphql } from "gatsby"
import { navigate } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { H1, Paragraph, PageWrapper, DefinitionList } from '../style/common/common'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Recaptcha from 'react-google-recaptcha'

const RECAPTCHA_KEY = '6LdaQJsfAAAAAEsRE9rDp9h5rDY1X2MqVekQ43i1'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactPage = ({ data, pageContext, location }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef()
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
  const title = "お問い合わせ"

  return (
    <Layout
      location={location}
    >
      <SEO
        title={`${title} | ${data.site.siteMetadata.title}`}
      />
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" / "
        crumbLabel={title}
      />
      <PageWrapper>
        <H1>{title}</H1>
        <Paragraph>小社へのお問い合わせは、以下のフォームまたは電話（0422-77-4020）にてお願いいたします。</Paragraph>
        <form
          name="contact"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-recaptcha="true"
          onSubmit={handleSubmit}
        >
          <noscript>
            <p>This form won’t work with Javascript disabled</p>
          </noscript>
          <div className="form__wrapper">
            <div className="form__ttl">
                <input type="text" required name="subject" placeholder="件名" onChange={handleChange} size="30" maxLength="245"/>
            </div>
            <div className="form__comment">
              <textarea name="message" required placeholder="お問合せ内容" onChange={handleChange}  rows="8" maxLength="65525"></textarea>
            </div>
            <div className="form__name__email">
              <div className="form__name">
                <input type="text" required name="name" placeholder="お名前" onChange={handleChange} size="30" maxLength="245"/>
              </div>
              <div className="form__email">
                <input type="email" required name="email" placeholder="Email" onChange={handleChange} size="30" maxLength="100" arua-describedby="email-notes"/>
              </div>
              <div className="form__tel">
                <input type="tel" required name="tel" placeholder="Tel" onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="g-recaptcha-wrapper">
            <Recaptcha className="g-recaptcha" ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
          </div>
          <div className="form__submit">
            <button type="submit">送信</button>
          </div>
        </form>

        <DefinitionList>
          {/* <dt>メール</dt>
          <dd>info★soryusha.co.jp（★を@に置き換えて使用してください）</dd>
          <dt>TEL</dt>
          <dd>0422-77-4020</dd> */}
        </DefinitionList>

        {/* <Link to="/">Go back to the homepage</Link> */}
      </PageWrapper>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
